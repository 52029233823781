<template>
  <div></div>
</template>

<script>
export default {
  name: 'BroadcastCommunication',
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>
